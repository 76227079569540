<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-4">
        <div
          class="widget-head-color-box blue-bg p-lg text-center"
          style="text-align: center"
        >
          <div class="m-b-md">
            <h2 class="font-bold no-margins">{{ getUserName() }}</h2>
            <small>{{ userInfo.roleName }}</small>
          </div>
          <img
            src="img/user-profile1.png"
            style="width: 25%"
            class="rounded-circle circle-border m-b-md"
            alt="profile"
          />
          <div>
            <span>{{ userInfo.customerName }} </span> |
            <span>{{ userInfo.groupName }} </span> |
            <span>{{ userInfo.lastLoginDate }}</span>
          </div>
        </div>
        <div class="widget-text-box">
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <button type="button" class="btn btn-primary m-r-sm">
                    {{ userInfo.totalAPI }}
                  </button>
                  Total API
                </td>
                <td>
                  <button type="button" class="btn btn-info m-r-sm">
                    {{ userInfo.requestCount }}
                  </button>
                  Total Request
                </td>
              </tr>

              <tr>
                <td>
                  <button type="button" class="btn btn-success m-r-sm">
                    {{ userInfo.successCount }}
                  </button>
                  Total Success
                </td>
                <td>
                  <button type="button" class="btn btn-danger m-r-sm">
                    {{ userInfo.failedCount }}
                  </button>
                  Total Failed
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
export default {
  data() {
    return {
      userInfo: {},
    };
  },
  created: function () {
  },
  mounted: function () {
    var self = this;
    setTimeout(() => {
      self.getUserInfo();
    }, 500);
  },
  methods: {
    getUserName: function () {
      return localStorage.getItem(Constant.data.SESSION_USER);
    },
    getUserInfo: function () {
      var self = this;
      AxiosService.get(
        Constant.url.GET_USER_INFO_URI +
          localStorage.getItem(Constant.data.USER_ID)
      ).then((result) => {
        self.userInfo = result;
        localStorage.setItem(Constant.data.ROLE_NAME, result.roleName);
      });
    },
  },
};
</script>
